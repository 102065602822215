import Component from '@glimmer/component';
import { service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { Drawer } from '@frontile/overlays';
import { registerCustomStyles } from '@frontile/theme';
import { tv } from 'tailwind-variants';
import type DrawerService from 'tio-ui/services/drawer';

// TODO: should this throw if the app tries to render multiple instances
// of it? it could register itself with the drawer service when the outer
// container mounts and have that service method assert that it's not
// already set

// TODO: decide whether this should live in the app page component in this
// addon on in an outer route template for whichever app needs it

// increases size of the drawer to max-w-4xl
registerCustomStyles({
  drawer: tv({
    slots: {
      base: '',
      closeButton: 'bg-gray-50',
      header: 'drawer__header',
      body: 'drawer__body',
      footer: 'drawer__footer',
    },
    variants: {
      size: {
        xl: 'absolute text-content1-foreground bg-content1 w-full h-full z-0 shadow top-0 bottom-0 right-0 max-w-4xl',
        lg: 'drawer--horizontal-lg absolute text-content1-foreground bg-content1 w-full h-full z-0 shadow top-0 bottom-0 right-0',
        // md: 'drawer--horizontal-md flex flex-col absolute text-content1-foreground bg-content1 w-full h-full z-0 shadow top-0 bottom-0 right-0',
        // sm: 'drawer--horizontal-sm flex flex-col absolute text-content1-foreground bg-content1 w-full h-full z-0 shadow top-0 bottom-0 right-0',
        // xs: 'drawer--horizontal-xs flex flex-col absolute text-content1-foreground bg-content1 w-full h-full z-0 shadow top-0 bottom-0 right-0',
      },
    },
  }) as never,
});

export default class DrawerTarget extends Component {
  @service declare drawer: DrawerService;

  onMount = modifier((element: HTMLElement) => {
    this.drawer.registerTargetElement(element);
    return this.drawer.resetTargetElement;
  });

  <template>
    <Drawer
      {{! defaults }}
      @placement={{this.drawer.defaultArgs.placement}}
      {{! pass throughs }}
      @size={{this.drawer.size}}
      @isOpen={{this.drawer.isOpen}}
      @allowCloseButton={{this.drawer.allowCloseButton}}
      @onClose={{this.drawer.onClose}}
      as |d|
    >
      <d.Body {{this.onMount}} class="!p-0 bg-white" />
    </Drawer>
  </template>
}
