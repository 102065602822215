import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tv } from 'tailwind-variants';
import type { Intent } from '../utilities';
import ExclamationTriangle from 'ember-static-heroicons/components/solid-24/exclamation-triangle';
import InfoCircle from 'ember-static-heroicons/components/solid-24/information-circle';
import CheckCircle from 'ember-static-heroicons/components/solid-24/check-circle';
import XCircle from 'ember-static-heroicons/components/solid-24/x-circle';

const eq = (a: unknown, b: unknown) => a == b;

const alertStyles = tv({
  slots: {
    base: 'flex items-center rounded-md p-4',
    iconContainer: 'flex flex-col justify-center flex-shrink mr-3',
  },
  variants: {
    intent: {
      default: {
        base: 'bg-gray-100 text-gray-700',
        iconContainer: 'text-gray-500',
      },
      primary: {
        base: 'bg-purple-50 text-purple-700',
        iconContainer: 'text-purple-500',
      },
      warning: {
        base: 'bg-yellow-50 text-yellow-700',
        iconContainer: 'text-yellow-500',
      },
      info: {
        base: 'bg-blue-50 text-blue-700',
        iconContainer: 'text-blue-500',
      },
      error: {
        base: 'bg-red-50 text-red-700',
        iconContainer: 'text-red-500',
      },
      success: {
        base: 'bg-green-50 text-green-700',
        iconContainer: 'text-green-500',
      },
      hidden: {
        base: 'hidden',
        iconContainer: 'hidden',
      },
    },
  },
});

interface AlertSignature {
  Args: {
    intent?: Intent;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class Alert extends Component<AlertSignature> {
  @action
  slotClass(slot: 'base' | 'iconContainer') {
    return alertStyles({ intent: this.args.intent })[slot]();
  }

  <template>
    <div class={{this.slotClass "base"}} ...attributes>
      <div class={{this.slotClass "iconContainer"}}>
        {{#if (eq @intent "warning")}}
          <ExclamationTriangle class="w-6" />
        {{else if (eq @intent "error")}}
          <XCircle class="w-6" />
        {{else if (eq @intent "success")}}
          <CheckCircle class="w-6" />
        {{else}}
          <InfoCircle class="w-6" />
        {{/if}}
      </div>
      <div>
        {{yield}}
      </div>
    </div>
  </template>
}
