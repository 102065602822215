import { object, string, number } from 'yup';

const agentLoanSchema = object({
  loanType: string().required('Please select a loan type.'),
  totalDisbursedAmount: number().required('Please enter an amount.'),
  currentBalance: number().required('Please enter a balance.'),
  interestRate: number().required('Please select an interest rate.'),
  monthlyPayment: string().required('Please enter a payment amount.'),
  repaymentPlan: string().required('Please select a repayment plan.'),
});

export { agentLoanSchema };

export default null; // silence a false warning
