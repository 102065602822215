import { debounce } from 'reactiveweb/debounce';
import { use } from 'ember-resources';
import { action } from '@ember/object';
import { Input } from 'tio-ui/components/forms';
import { or } from 'tio-ui/utilities';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MagnifyingGlass from 'ember-static-heroicons/components/outline-24/magnifying-glass';
import type Owner from '@ember/owner';

const DELAY = 250;

interface SearchInputSignature {
  Args: {
    ariaLabel?: string;
    onInput: (value: string) => void;
    onSubmit: (value: string) => void;
    placeholder?: string;
    searchString: string;
    label?: string;
  };
  Element: HTMLInputElement;
}

export default class SearchInputComponent extends Component<SearchInputSignature> {
  @tracked userInput = '';
  @use debouncedInput = debounce(DELAY, () => this.userInput, this.userInput);

  constructor(owner: Owner, args: SearchInputSignature['Args']) {
    super(owner, args);
    if (this.args.searchString) {
      this.userInput = this.args.searchString;
    }
  }

  @action
  clearInput() {
    this.userInput = '';
    this.args.onInput?.('');
  }

  @action
  didInput(value: string) {
    this.userInput = value;
    this.args.onInput?.(value);
  }

  @action
  didChange(value: string) {
    this.args.onSubmit?.(value);
  }

  <template>
    <Input
      @isClearable={{true}}
      @onInput={{this.didInput}}
      @onChange={{this.didChange}}
      @type="search"
      @value={{this.userInput}}
      @label={{@label}}
      aria-label={{or @ariaLabel "Search"}}
      autocomplete="off"
      class="ps-10 pe-10"
      name="search-input"
      placeholder={{@placeholder}}
      ...attributes
    >
      <:startContent>
        <MagnifyingGlass class="w-5" />
      </:startContent>
    </Input>
  </template>
}
