import Component from '@glimmer/component';
import { service } from '@ember/service';
import { modifier } from 'ember-modifier';
import type DrawerService from '../services/drawer';

interface DrawerContentSignature {
  Args: {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
    isOpen: boolean;
    allowCloseButton?: boolean;
    onClose?: () => void;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class DrawerContentComponent extends Component<DrawerContentSignature> {
  @service declare drawer: DrawerService;

  onMount = modifier(() => {
    const { isOpen, allowCloseButton, onClose, size } = this.args;
    this.drawer.registerTargetArgs({ isOpen, allowCloseButton, onClose, size });

    return this.drawer.resetTargetArgs;
  });

  <template>
    {{#if @isOpen}}
      <span class="hidden" {{this.onMount}} />
      {{#if this.drawer.targetElement}}
        {{#in-element this.drawer.targetElement}}
          <div ...attributes>
            {{yield}}
          </div>
        {{/in-element}}
      {{/if}}
    {{/if}}
  </template>
}
