import { attr, belongsTo, hasMany } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type CompanyModel from './company.ts';
import type RawFileDigitalAssetModel from './raw-file-digital-asset.ts';
import type FileHistoryLogModel from './file-history-log.ts';

//const MODEL_NAME = 'file-history'; // used for dispute - CHECK USAGE

export const FILE_STATES = {
  'FILE_STATE.UPLOADED': 1, // probably won't ever see this state, VERIFY?
  'FILE_STATE.DISPATCHED': 2,
  'FILE_STATE.PROCESSING': 3,
  'FILE_STATE.INVALID': 3,
  'FILE_STATE.FAILED': 3,
  'FILE_STATE.PROCESSED': 4,
  'FILE_STATE.DERELICT': 4,
};

// might not be required
export const SUBSISTENCE_STATES = [
  'SubsistenceState.IMPLICIT',
  'SubsistenceState.IN_DISPUTE',
  'SubsistenceState.EXPLICIT',
  'SubsistenceState.REJECTED',
];

// post preprocessing consideration
export const REFLECTION_STATES = [
  'ReflectionState.NOT_APPLIED',
  'ReflectionState.IN_PROGRESS',
  'ReflectionState.APPLIED',
  'ReflectionState.PARTIALLY_APPLIED',
];

export default class FileHistoryModel extends DigitalAsset {
  /*************************
   **  Attributes         **
   *************************/
  // unique identifier associated with a batch of documents that were uploaded together
  // @attr declare batch: string; // same as operation_id?
  @attr declare recordType: 'PartnerDataTransfer';
  @attr declare operationId: string; //uuid
  @attr declare insertedCount: number;
  @attr declare failedInsertionCount: number;
  @attr declare totalCount: number; //needed?
  @attr declare percentageCompletion: number;
  @attr declare sizeConfig: Record<string, unknown>;
  @attr declare tioFileName: string;
  @attr declare tioTotal: Record<string, unknown>;
  // @attr declare fileSizeActual: number;
  @attr declare contentType: string;

  @attr declare createdBy: string;
  @attr declare createdAt: string;
  @attr declare processingStartAt: string;
  @attr declare processingEndAt: string;

  @attr declare fileState: keyof typeof FILE_STATES;
  @attr declare subsistenceState: (typeof SUBSISTENCE_STATES)[number];
  @attr declare reflectionState: (typeof REFLECTION_STATES)[number];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: 'fileHistories' })
  declare company: CompanyModel;

  @belongsTo('raw-file-digital-asset', { async: false, inverse: 'fileHistory' })
  declare rawFileDigitalAsset: RawFileDigitalAssetModel; //hasOne not hasMany

  @hasMany('file-history-log', { async: false, inverse: 'fileHistory' })
  declare fileHistoryLogs: FileHistoryLogModel[];

  /**************************
   **  Computed Properties **
   **************************/

  //  get percentageCompletion(): number {
  //   return this.insertedCount / this.totalCount;
  //  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    dataTransferAgentFileHistory: FileHistoryModel;
  }
}
