import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import fileQueue from 'ember-file-upload/helpers/file-queue';
import type { UploadFile } from 'ember-file-upload';
import FileDropzone from 'ember-file-upload/components/file-dropzone';
import CloudArrowUp from 'ember-static-heroicons/components/outline-24/cloud-arrow-up';
import { and, not, gt } from '../utilities.ts';

type FileUploaderSignature = {
  Args: {
    name: string;
    accept?: string;
    multiple?: boolean;
    disabled?: boolean;
    useDropzone?: boolean;
    onFileAdded?: (upload: UploadFile) => void;
    onFileRemoved?: (upload: UploadFile) => void;
  };
  Blocks: {
    default: [];
  };
};

class FileUploader extends Component<FileUploaderSignature> {
  @tracked uploads: UploadFile[] = [];

  @action
  async handleFileAdded(upload: UploadFile) {
    const { onFileAdded } = this.args;

    // If there's no extension, attempt to detect the file type
    if (!upload.file.name.includes('.')) {
      const arrayBuffer = await upload.file.arrayBuffer();
      // Read the first 5 bytes to check for "%PDF-"
      const firstBytes = new Uint8Array(arrayBuffer.slice(0, 5));
      const firstChars = new TextDecoder().decode(firstBytes);

      if (firstChars === '%PDF-') {
        upload.file = new File([upload.file], `${upload.file.name}.pdf`, {
          type: 'application/pdf',
        });
      }
    }

    this.uploads = [...this.uploads, upload];
    if (onFileAdded) onFileAdded(upload);
  }

  @action
  handleFileRemoved(upload: UploadFile) {
    const { onFileRemoved } = this.args;
    this.uploads = this.uploads.filter((currentUpload) => currentUpload !== upload);
    if (onFileRemoved) onFileRemoved(upload);
  }

  get disabled() {
    const { disabled = false, multiple = false } = this.args;
    if (!multiple && this.uploads.length) {
      return true;
    }
    return disabled;
  }

  <template>
    {{#let
      (fileQueue name=@name onFileAdded=this.handleFileAdded onFileRemoved=this.handleFileRemoved)
      as |queue|
    }}
      <FileDropzone @queue={{queue}} as |dropzone|>
        {{#if (and dropzone.supported @useDropzone)}}
          <label
            {{!
              min-h-[9rem] here is equivalent to the intended min-h-36; as of mid 2024 there's a tailwind
              but where min-h-* doesn't work as expected but min-h-[*] does - James 20241030
            }}
            class="flex flex-col items-center justify-center w-full min-h-[9rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            {{#if dropzone.active}}
              <span class="text-sm text-gray-500">Drop to upload</span>
            {{else if (and queue.files.length (not queue.progress))}}
              <span class="text-sm text-gray-500">{{queue.files.length}}
                {{if (gt queue.files.length 1) "files" "file"}}
                ready to upload.</span>
            {{else if (and queue.files.length queue.progress)}}
              <span class="text-sm text-gray-500">Uploading
                {{queue.files.length}}
                {{if (gt queue.files.length 1) "files" "file"}}
                . ({{queue.progress}}%)</span>
            {{else}}
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <CloudArrowUp class="w-8 h-8 mb-2" />
                <span class="block mb-2 text-sm text-gray-500">
                  <strong>Select a file</strong>
                  or drag and drop here to upload
                </span>
              </div>
            {{/if}}
            <input
              {{queue.selectFile}}
              disabled={{this.disabled}}
              type="file"
              class="sr-only"
              accept={{@accept}}
            />
          </label>
        {{else}}
          <label class="hover:cursor-pointer">
            {{yield}}
            <input
              {{queue.selectFile}}
              disabled={{this.disabled}}
              type="file"
              class="sr-only"
              accept={{@accept}}
            />
          </label>
        {{/if}}
      </FileDropzone>
    {{/let}}
  </template>
}

export default FileUploader;
