import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import { tv } from 'tailwind-variants';
import type { Intent } from '../utilities';

const badge = tv({
  base: 'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
  variants: {
    intent: {
      default: 'bg-gray-100 text-gray-700 ring-gray-600/20',
      primary: 'bg-purple-50 text-purple-700 ring-purple-700/10',
      warning: 'bg-yellow-50 text-gray-700 ring-yellow-600/20',
      info: 'bg-blue-50 text-blue-700 ring-blue-600/20',
      error: 'bg-red-50 text-red-700 ring-red-600/20',
      success: 'bg-green-50 text-green-700 ring-green-600/20',
      hidden: 'hidden',
    },
  },
});

interface BadgeSignature {
  Args: {
    intent?: Intent;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLSpanElement;
}

const Badge: TOC<BadgeSignature> = <template>
  <span class={{badge (hash intent=(if @intent @intent "default"))}} ...attributes>
    {{yield}}
  </span>
</template>;

export default Badge;
