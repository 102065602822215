import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { getSingleValueForTasField } from '../utils/tuition-assistance/fields.ts';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { date, minValue, maxValue, object, pipe } from 'valibot';
import type EmployeeModel from '../models/employee.ts';
import type FeaturesService from './features.ts';
import type IntlService from 'ember-intl/services/intl';
import type LimitModel from '../models/limit.ts';

import type Store from '@ember-data/store';
// @ts-expect-error: this doesn't exist yet
import type SessionContextService from './session-context.ts';
import type TASApplicationModel from '../models/tas-application.ts';
import type TasAssistanceModel from '../models/tas-assistance.ts';
import type TasEligibilityModel from '../models/tas-eligibility.ts';
import type TasParticipantModel from '../models/tas-participant.ts';
import type TASProgramTemplateModel from '../models/tas-program-template.ts';
import type TuitionAssistanceLimitsSummaryComponent from '../components/tuition-assistance/limits-summary.gts';

type LimitsRequestParamsSignature = {
  employee_ids: string[];
  excluded_item_ids: string[];
  company_id: string;
  product: string;
  scheme?: string;
  batch?: string;
  group?: string | null;
  date?: string;
  date_based_on?: string;
  course_start?: string;
  course_end?: string;
  end_of_year_cutoff?: number | null;
  requester: string | null;
  approval?: string | null;
  payment_date?: string;
  dependent_id?: string;
  application_id?: string;
  is_prepaid?: boolean;
};

type LimitsStatusRequestResponseSignature = {
  data: LimitsStatusDataSignature[];
};

type LimitName =
  | 'company_annual'
  | 'company'
  | 'product_annual'
  | 'product'
  | 'batch_annual'
  | 'batch'
  | 'scheme_annual'
  | 'scheme'
  | 'none';

export type LimitsStatusDataSignature = {
  annual_end_date: string;
  annual_start_date: string;
  available: number | null;
  available_with_override: number | null;
  details: {
    company: LimitsDetailSignature;
    product: LimitsDetailSignature;
    batch: LimitsDetailSignature;
    scheme: LimitsDetailSignature;
  };
  employee_id: string;
  hard_limit: LimitName;
  product: string | null;
  scheme: string | null;
  soft_limit: LimitName;
};

export type LimitsDetailSignature = {
  annual_limit: number | null;
  annual_usage: number;
  available: number | null;
  hard: boolean | null;
  limit: number | null;
  one_month_limit: number | null;
  one_month_usage: number;
  six_month_limit: number | null;
  six_month_usage: number;
  taxable_limit: number | null;
  three_month_limit: number | null;
  three_month_usage: number;
  usage: number;
};

type ApplicationValidationResponseSignature = {
  can_submit: boolean;
  reduction_needed: number;
};

type CourseDateValidationResult = {
  date: Date;
  message: string;
};

export default class TuitionAssistanceService extends Service {
  @service declare intl: IntlService;
  @service declare store: typeof Store;
  @service declare sessionContext: SessionContextService;
  @service declare features: FeaturesService;

  activeLimitsSummaryComponents: Set<TuitionAssistanceLimitsSummaryComponent> = new Set();

  @tracked activeEligibilities: TasEligibilityModel[] = [];
  @tracked limitsData: LimitsStatusDataSignature | undefined = undefined;
  @tracked gpaBasedAmount: number = 0;
  @tracked programTemplate: TASProgramTemplateModel | undefined = undefined;

  get host() {
    return this.store.adapterFor('application').host;
  }

  get headers() {
    return this.store.adapterFor('application').headers;
  }

  setBatchGroup(
    application: TASApplicationModel,
    applicationLimit: LimitModel | undefined
  ): string | undefined {
    const tasGroups = application.tasProgramInstance.tasProgramTemplate?.tasGroups || [];

    const sharedLimitGroup = tasGroups.find(
      (group) => group.flavor === 'TAS.GroupFlavor.SHARED_LIMIT'
    );

    // Return the group id if found, otherwise fallback to application.group
    return sharedLimitGroup?.id ?? applicationLimit?.group;
  }

  get availableVoucherAmount() {
    return this.limitsData?.available ?? 0;
  }

  @action
  async getLimitForTasApplication(
    tasApplication: TASApplicationModel
  ): Promise<LimitModel | undefined> {
    const url = `${this.host}/tas-applications/${tasApplication.id}/limit`;

    try {
      const response = await fetch(url, {
        headers: this.headers,
        method: 'GET',
      });

      const parsed = await response.json();

      this.store.pushPayload(parsed);

      const limitModel = this.store.peekRecord('limit', parsed.data.id);

      return limitModel;
    } catch (e) {
      console.error(`Error fetching limit for TasApplication ${tasApplication.id}`, {
        tasApplicationId: tasApplication.id,
        url,
        error: e,
      });
    }
  }

  @action
  async getApplicationLimitsDataForEmployee(
    employee: EmployeeModel,
    applicationLimit: LimitModel | undefined,
    application: TASApplicationModel
  ): Promise<LimitsStatusDataSignature | undefined> {
    const adapter = this.store.adapterFor('tas-application');

    const scheme = applicationLimit?.scheme;
    const batchGroup = this.setBatchGroup(application, applicationLimit);

    const url = `${this.host}/limits/status`;

    const company = employee.company;

    if (!company) {
      console.error(
        'Error in `getApplicationLimitsDataForEmployee`: Company is not defined on employee.'
      );
    }

    const params: LimitsRequestParamsSignature = {
      employee_ids: [employee.id],
      excluded_item_ids: [],
      company_id: company?.id,
      product: 'TA',
      scheme: scheme,
      requester: 'employee',
      batch: batchGroup,
      application_id: application?.id,
      is_prepaid: application?.isPrepaidProgram,
    };

    const programTemplate = application?.tasProgramInstance?.tasProgramTemplate;

    if (application && !programTemplate) {
      console.error(
        'Error in `getApplicationLimitsDataForEmployee`: An application was passed with no related program template loaded.'
      );
    }

    if (application && programTemplate) {
      params.group = programTemplate.id;
      params.course_start = getSingleValueForTasField('COURSES_BEGIN_DATE', application.fields) as
        | string
        | undefined;
      params.course_end = getSingleValueForTasField('COURSES_END_DATE', application.fields) as
        | string
        | undefined;
      params.date_based_on = getSingleValueForTasField(
        'CALCULATE_TOWARDS_TOTAL_BASED_ON',
        programTemplate.fields
      ) as string | undefined;
      params.end_of_year_cutoff = getSingleValueForTasField(
        'PAYMENT_SUBMISSION_MIN_DAYS_BEFORE_YEAR_END',
        programTemplate.fields
      ) as number | null | undefined;

      if (this.features.isAdminApp) {
        params.requester = this.sessionContext.user.isTasApprover ? 'approver' : 'admin';
      } else if (
        `${this.sessionContext.currentRole?.relationshipType}`.startsWith('TAS.Approver')
      ) {
        params.requester = 'approver';
      }

      switch (application.state) {
        case 'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL':
          params.approval = 'evidence';
          break;
        case 'TAS.ApplicationState.PENDING_COURSES_APPROVAL':
          params.approval = 'course';
          break;
        case 'TAS.ApplicationState.FULFILLED':
          params.payment_date = application.latestPaymentDate;
          break;
        default:
          params.approval = null;
      }

      if (application.tasProgramInstance.isDependentProgram) {
        params.dependent_id = application.tasProgramInstance.dependent?.id;
        params.product = 'DEPENDENT_TA';
      }

      if (!application.isDormantState) {
        try {
          const applicationWithAssistances = await this.store.findRecord(
            'tas-application',
            application.id,
            {
              include: 'tas-assistances',
              reload: true,
            }
          );
          const assistanceIds = applicationWithAssistances.tasAssistances.map(
            (assistance: TasAssistanceModel) => assistance.id
          );
          params.excluded_item_ids = assistanceIds;
        } catch (e) {
          console.error(
            'Error in `getApplicationLimitsDataForEmployee`: Unable to fetch tas assistances to exclude',
            {
              params,
              error: e,
            }
          );
        }
      }
    }

    try {
      const limitsData: LimitsStatusRequestResponseSignature | undefined = await adapter.ajax(
        url,
        'GET',
        {
          data: params,
        }
      );

      this.limitsData = limitsData?.data?.[0];
      return this.limitsData;
    } catch (e) {
      console.error(`Unable to fetch limits status`, {
        params,
        error: e,
      });
    }
  }

  @action
  async getApplicationLimitStatusByEmployeeId(
    employee: EmployeeModel
  ): Promise<LimitsStatusDataSignature | undefined> {
    const adapter = this.store.adapterFor('tas-application');

    const url = `${this.host}/limits/status`;

    const company = employee.company;

    if (!company) {
      console.error(
        'Error in `getApplicationLimitStatusByEmployee`: Company is not defined on employee.'
      );
    }

    const params: LimitsRequestParamsSignature = {
      employee_ids: [employee.id],
      excluded_item_ids: [],
      company_id: company?.id,
      requester: 'employee',
      product: 'TA',
    };

    try {
      const limitsStatus = await adapter.ajax(url, 'GET', {
        data: params,
      });
      const limitsData: LimitsStatusDataSignature = limitsStatus?.data?.[0];
      return limitsData;
    } catch (error) {
      console.error('Error fetching limits status:', error);
      throw error;
    }
  }

  @action
  async validateAmountRequestedAgainstCurrentLimitsStatus(
    application: TASApplicationModel,
    employee: EmployeeModel
  ): Promise<ApplicationValidationResponseSignature> {
    const allowSubmissionBeyondLimits =
      getSingleValueForTasField(
        'ALLOW_PRE_APPROVAL_SUBMISSION_BEYOND_LIMIT',
        application.tasProgramInstance.tasProgramTemplate.fields
      ) || false;
    if (allowSubmissionBeyondLimits) {
      return {
        can_submit: true,
        reduction_needed: 0,
      };
    }

    const applicationLimit = await this.getLimitForTasApplication(application);
    const limitStatus = await this.getApplicationLimitsDataForEmployee(
      employee,
      applicationLimit,
      application
    );

    const remainingBenefit = limitStatus?.available ?? null;
    if (remainingBenefit === null) {
      return {
        can_submit: true,
        reduction_needed: 0,
      };
    }
    const amountRequested = application.requestedTotal || 0;
    const remainder = remainingBenefit - amountRequested;
    const isValid = remainder >= 0;

    return {
      can_submit: isValid,
      reduction_needed: isValid ? 0 : Math.abs(remainder),
    };
  }

  @action
  async triggerAutoEvidenceApprovalForApplication(
    application: TASApplicationModel,
    comment: string = ''
  ) {
    if (!getSingleValueForTasField('WAIVE_EVIDENCE_APPROVAL_REQUIREMENT', application.fields)) {
      console.error(
        `Application ${application.id} cannot be submitted for auto approval without evidence approval waiver.`
      );
      return;
    }

    await this.autoApproveEvidenceTask.perform(application, comment);
  }

  /* At this point, the application should already have evidence approval waived by courses approval.
   * That means that as soon as we auto-request evidence approval, it should move to fulfilled as we expect.
   * This action lives on a service because of the distributed nature of the state machine. We need to ensure
   * this task is not cancelled because of a parent component being destroyed.
   * However, we do draw the line at 15 seconds of polling for the application
   * to move into valid state for requesting evidence approval.
   */
  autoApproveEvidenceTask = task(
    { maxConcurrency: 10, enqueue: true },
    async (application: TASApplicationModel, comment: string = '') => {
      let attemptCount = 0;

      while (application.state !== 'TAS.ApplicationState.ATTEND' && attemptCount < 30) {
        attemptCount++;
        await timeout(500);
        await application.reload();
      }

      try {
        await this.store.adapterFor('tas-application').requestCourseEvidence(application, comment);
      } catch (e) {
        console.error('autoApproveEvidenceTask encountered an error', {
          applicationId: application.id,
          error: e,
        });
      }
    }
  );

  @action
  registerLimitsSummaryComponent(component: TuitionAssistanceLimitsSummaryComponent) {
    this.activeLimitsSummaryComponents.add(component);
  }

  @action
  unregisterLimitsSummaryComponent(component: TuitionAssistanceLimitsSummaryComponent) {
    this.activeLimitsSummaryComponents.delete(component);
  }

  @action
  refreshActiveLimitsSummaryComponents() {
    try {
      this.activeLimitsSummaryComponents.forEach((component) => component.refreshData());
    } catch (e) {
      console.error('Error refreshing active limits summary components', {
        error: e,
      });
    }
  }

  @action
  async loadEligibilitiesForCurrentEmployee() {
    const participants = await this.store.query('tas-participant', {
      filter: {
        employee: this.sessionContext.currentEmployee.id,
      },
      include: 'tas-eligibilities',
    });

    this.activeEligibilities = (participants[0]?.tasEligibilities || []).filter(
      (model: TasEligibilityModel) => model.isActive
    );
  }

  @action
  hasActiveEligibilityForProgramTemplate(programTemplate: TASProgramTemplateModel) {
    return this.activeEligibilities.some(
      (eligibility: TasEligibilityModel) => eligibility.code === programTemplate.code
    );
  }

  @action
  waitingPeriodEndDate(waitingPeriod: number, tasParticipant: TasParticipantModel) {
    const waitingPeriodToNumber = Number(waitingPeriod);
    if (!tasParticipant?.employmentStartDate || waitingPeriodToNumber === 0) {
      return ''; // Return '' to indicate that the waiting period end date can't be calculated or is 0
    }

    const hireDate = new Date(tasParticipant.employmentStartDate);
    const endDate = new Date(hireDate);
    endDate.setDate(hireDate.getDate() + waitingPeriodToNumber);
    return endDate;
  }

  @action
  ineligibleBasedOnWaitingPeriod(
    waitingPeriod: number,
    tasParticipant: TasParticipantModel,
    dateToCheck?: string
  ) {
    const endDate = this.waitingPeriodEndDate(waitingPeriod, tasParticipant);
    if (!endDate) {
      return false; // If no end date, participant is not ineligible based on waiting period
    }

    // If no dateToCheck (typically from course start date) is provided, use today's date
    const applicationDate = dateToCheck ? new Date(dateToCheck) : new Date();
    const result = applicationDate < endDate;

    return result;
  }

  @action
  setTotalBasedOnGPA(gpa: number) {
    // values are in cents
    if (gpa >= 3.5) {
      this.gpaBasedAmount = 300000;
    } else if (gpa >= 2.75) {
      this.gpaBasedAmount = 225000;
    } else if (gpa >= 2.5) {
      this.gpaBasedAmount = 75000;
    } else {
      this.gpaBasedAmount = 0;
    }
    return this.gpaBasedAmount;
  }

  addDays(date: Date, days: number): Date {
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  }

  firstDayToSubmitApplicationForPreApproval(
    programTemplate: TASProgramTemplateModel
  ): CourseDateValidationResult | null {
    const leadTimeDays = Number(programTemplate.coursesPreApprovalSubmissionMaxLeadTime) || null;
    if (!leadTimeDays) {
      return null;
    }

    const today = new Date();
    const submissionDeadline = this.addDays(today, leadTimeDays);
    const formattedDeadline = this.intl.formatDate(submissionDeadline, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return {
      date: submissionDeadline,
      message: `Your employer requires that you submit your application within ${leadTimeDays} days of the start date. Change date to be on or before ${formattedDeadline}.`,
    };
  }

  courseMaximumBeginDateWithErrorMessaging(
    programTemplate: TASProgramTemplateModel
  ): CourseDateValidationResult | null {
    // If the program has a defined pre-approval submission lead time, no error messaging is needed.
    if (!programTemplate.coursesPreApprovalSubmissionMaxLeadTime) {
      return null;
    }
    const preApprovalMaxLeadTimeResult =
      this.firstDayToSubmitApplicationForPreApproval(programTemplate);
    if (!preApprovalMaxLeadTimeResult) {
      return {
        date: new Date('2099-01-01'),
        message: this.intl.t(
          'tuition_assistance.program_details.courses.validation.defaults.begin_date'
        ),
      };
    }

    return preApprovalMaxLeadTimeResult;
  }

  lastPossibleBeginDateWithErrorMessaging(
    programTemplate: TASProgramTemplateModel
  ): CourseDateValidationResult | null {
    let daysOffset: number | null = null;
    let policyMessage = '';

    const { lastDayToSubmitApplicationForPreApproval, coursesPreApprovalSubmissionMinLeadTime } =
      programTemplate;

    if (lastDayToSubmitApplicationForPreApproval) {
      daysOffset = -Number(lastDayToSubmitApplicationForPreApproval) || null;
      if (daysOffset) {
        policyMessage = `Your employer requires that you submit your application within ${Math.abs(daysOffset)} days after the start date.`;
      }
    } else if (coursesPreApprovalSubmissionMinLeadTime) {
      daysOffset = Number(coursesPreApprovalSubmissionMinLeadTime) || null;
      if (daysOffset) {
        policyMessage = `Your employer requires that you submit your application at a minimum of ${daysOffset} days before the start date.`;
      }
    }

    if (!daysOffset) {
      return null;
    }

    const today = new Date();
    const deadlineDate = this.addDays(today, daysOffset);

    return {
      date: deadlineDate,
      message: policyMessage,
    };
  }

  courseMinimumBeginDateWithErrorMessaging(
    programTemplate: TASProgramTemplateModel
  ): CourseDateValidationResult {
    const lastDayToSubmit = this.lastPossibleBeginDateWithErrorMessaging(programTemplate);
    if (!lastDayToSubmit) {
      return {
        date: new Date('2000-01-01'),
        message: this.intl.t(
          'tuition_assistance.program_details.courses.validation.defaults.begin_date'
        ),
      };
    }

    return lastDayToSubmit;
  }

  @action
  dynamicBeginDateValidationSchema(programTemplate: TASProgramTemplateModel) {
    const maxDate = this.courseMaximumBeginDateWithErrorMessaging(programTemplate);
    const minDate = this.courseMinimumBeginDateWithErrorMessaging(programTemplate);

    const validators = [
      date(),
      ...(minDate ? [minValue<Date, Date, string>(minDate.date, minDate.message)] : []),
      ...(maxDate ? [maxValue<Date, Date, string>(maxDate.date, maxDate.message)] : []),
    ] as const;

    const validation = object({
      dateEntered: pipe(...validators),
    });

    return validation;
  }
}
