import Model, { attr, belongsTo } from '@ember-data/model';
import type FileHistoryModel from './file-history';

export type LogSource = [
  'dispatch',
  'process',
  'processed',
  'processed_with_errors_or_invalid',
  'processing_invalid',
  'processing_failed',
  'insert',
][number];

export default class FileHistoryLogModel extends Model {
  /*************************
   **  Attributes         **
   *************************/
  // TODO: type this
  @attr declare detail: { note: string; changes: { [key: string]: unknown } };

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('file-history', { async: false, inverse: 'fileHistoryLogs' })
  declare fileHistory: FileHistoryModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    fileHistoryLog: FileHistoryLogModel;
  }
}
