// import { modifier } from 'ember-modifier';
import { action } from '@ember/object';
import { Collapsible } from 'tio-ui/components/utilities';
import { on } from '@ember/modifier';
import { and, or } from '../utilities.ts';
import { tracked } from '@glimmer/tracking';
import ChevronDown from 'ember-static-heroicons/components/outline-24/chevron-down';
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import type Owner from '@ember/owner';

const SectionStyle = `
  [&>section]:bg-white [&>section]:border:border-none
   md:[&>section]:border md:[&>section]:rounded-lg
`;

const SectionDarkStyle =
  '[&>section]:dark:border [&>section]:dark:rounded-lg [&>section]:dark:bg-gray-800';

interface VStackSignature {
  Args: {
    /**
     * Experimental dark mode. As we disable darkmode globally we can't easily test
     * small introductions of dark mode. This is a way to test dark mode in a single
     * component.
     *
     * @optional
     * @type {boolean}
     */
    darkMode?: boolean;
    /**
     * When true the direct children will be separated by a horizontal divider
     *
     * @optional
     * @type {boolean}
     */
    collapsed?: boolean;
  };
  Element: HTMLDivElement;
  Blocks: { default: [] };
}
/**
 * Vertical Stack
 *
 * A view that arranges its subviews in a vertical line.
 */
export const VStack: TOC<VStackSignature> = <template>
  <div
    class="p-4 space-y-4 w-full [&>[data-vstack]]:p-0
      {{unless @collapsed SectionStyle}}
      {{if @darkMode SectionDarkStyle ''}}
      {{if
        @collapsed
        'md:divide-y divide-gray-300 [&>section:not(:first-child):not(:has([data-section-body]))]:pt-4'
      }}
      "
    {{!-- {{if @collapsed 'p-0 md:[&>section:not(:first-child)]:pt-4' 'p-0 md:p-4'}}
       " --}}
    data-vstack
    ...attributes
  >
    {{yield}}
  </div>
</template>;

interface HStackSignature {
  Args: {
    /**
     * When true the direct childred will be separated by a vertical divider
     *
     * @optional
     * @type {boolean}
     */
    collapsed?: boolean;
  };
  Element: HTMLDivElement;
  Blocks: { default: [] };
}
/** Horizontal Stack
 *
 * A view that arranges its subviews in a horizontal line.
 */
export const HStack: TOC<HStackSignature> = <template>
  <div
    class="[&>section]:w-full flex flex-col px-0 space-y-4 items-start [&>[data-vstack]]:p-0
      {{unless @collapsed SectionStyle}}
      {{if @collapsed 'divide-y divide-x-0 md:divide-y-0 md:divide-x divide-gray-300 px-0'}}
      md:flex-row md:space-x-4 md:space-y-0"
    data-hstack
    ...attributes
  >
    {{yield}}
  </div>
</template>;

// TODO: return to this. figure out the rule that would make a header adjust its color based on its parent
// or just make another component called sub header.
// const headerCheck = modifier(
//   (element: HTMLElement) => {
//     let parent = element.parentElement;
//     let grandparent = parent?.parentElement;

//     // Condition:
//     // 1. The parent has 'data-vstack'
//     // 2. The grandparent also has 'data-vstack'
//     // 3. This element is the parent's first child
//     if (
//       parent?.hasAttribute('data-vstack') &&
//       grandparent?.hasAttribute('data-vstack') &&
//       parent.firstElementChild === element
//     ) {
//       element.classList.add('text-gray-600');
//     } else {
//       element.classList.add('text-midnight', 'text-xl');
//     }
//   }
// );

interface HeaderSignature {
  Args: {};
  Element: HTMLElement;
  Blocks: { default: [] };
}
export const Header: TOC<HeaderSignature> = <template>
  <header class="font-semibold text-xl text-midnight" data-header ...attributes>
    {{yield}}
  </header>
</template>;

interface SectionSignature {
  Args: {
    headerClass?: string;
    collapsed?: boolean;
  };
  Element: HTMLElement;
  Blocks: { default: []; header: []; subHeader: []; actions: []; body: [] };
}
/** Section
 *
 * A container view that you can use to add hierarchy within certain views.
 *
 * Use Sections in views like HStack and VStack to organize content
 * into separate sections. Each section has custom content that you
 * provide on a per-instance basis. You can also provide headers section.
 */
export class Section extends Component<SectionSignature> {
  @tracked isOpen = true;

  constructor(owner: Owner, args: SectionSignature['Args']) {
    super(owner, args);
    if (args.collapsed) {
      this.isOpen = !args.collapsed;
    }
  }

  get isCollapsible() {
    return this.args.collapsed !== undefined;
  }

  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }

  <template>
    <section data-section ...attributes>
      {{#if
        (or this.isCollapsible (has-block "header") (has-block "subHeader") (has-block "actions"))
      }}
        <div class="flex items-start gap-x-4 px-0 md:px-4 py-4">
          {{#if this.isCollapsible}}
            <button type="button" class="hover:text-ocean-800 h-6" {{on "click" this.toggle}}>
              <ChevronDown
                class="{{if this.isOpen 'rotate-0' '-rotate-90'}} w-4 h-4 text-ocean-600"
              />
            </button>
          {{/if}}
          <div
            class="flex justify-between w-full
              {{if this.isOpen 'group expanded' 'group collapsed'}}"
          >
            <div class="spacing-y-1">
              {{#if (has-block "header")}}
                <h1 class="{{@headerClass}}" data-section-header>
                  {{yield to="header"}}
                </h1>
              {{/if}}
              {{#if (has-block "subHeader")}}
                <h2 class="text-sm" data-section-sub-header>
                  {{yield to="subHeader"}}
                </h2>
              {{/if}}
            </div>
            {{#if (has-block "actions")}}
              <HStack
                class="transition-opacity duration-300 group-[.expanded]:opacity-100 group-[.collapsed]:opacity-0 group-[.collapsed]:pointer-events-none group-[.collapsed]:delay-300 group-[.collapsed]:max-h-0"
              >
                {{yield to="actions"}}
              </HStack>
            {{/if}}
          </div>
        </div>
        {{#if this.isOpen}}
          <div class="h-0 border-b mx-0 md:mx-4"></div>
        {{/if}}
      {{/if}}

      {{#if (has-block "body")}}
        <Collapsible @isOpen={{this.isOpen}}>
          {{! Need to do more testing around these p-0 overrides. So far immediate v/hstack indicates
              pure layout and the body is providing the padding. recommendations.gts is why I added this. }}
          <div
            class="px-0 py-4 md:p-4 md:[&:has(> [data-vstack]:first-child) > [data-vstack]]:!p-0 [&>[data-vstack]:first-child]:p-0 [&>[data-hstack]:first-child]:p-0"
            data-section-body
          >
            {{yield to="body"}}
          </div>
        </Collapsible>
      {{/if}}
      {{yield}}
    </section>
  </template>
}
