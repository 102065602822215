import Component from '@glimmer/component';
import { service } from '@ember/service';
import type { SafeString } from '@ember/template';
import type IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';
import { htmlSafe } from '@ember/template';

// TODO: write tests for this if it we decide it's sane enough to use - james 2024-12-18

interface ActionableTranslationSignature {
  Args: {
    t: string;
    params?: {
      [key: string]: string;
    };
    links?: {
      [key: string]: {
        t: string;
        href: string;
        linkClass?: string;
        target?: string;
        rel?: string;
      };
    };
    actions?: {
      [key: string]: {
        t: string;
        onClick: () => void;
        buttonClass?: string;
      };
    };
  };
}
/**
 * ActionableTranslation
 *
 * An attempt to keep markup out of the more complicated translations while still
 * rendering semantically meaningful tags
 *
 * Accepts a main translation string arg "t" for the main translation file content
 * to render, and configuration objects for links or actions to interpolate into the
 * translation. Config object properties must include separate translation strings
 * to look up what text to use in the sort of pre-render steps where linkify and
 * buttonify methods return html safe strings to pass as params to intl.t when it
 * renders the main translation
 *
 * usage:
 *
 * (translation yaml file)
 * click_here: click here
 * some:
 *   translation:
 *     key: you can {goToGoogle} to navigate to Google or {toggle} to toggle something
 *
 * (component/template file)
 * <ActionableTranslation
 *   @t="some.translation.string"
 *   @links={{hash goToGoogle=(hash href="http://www.google.com" t="click_here")}}
 *   @actions={{hash toggle=(hash onClick=this.toggleSomething t="click_here")}}
 * />
 */
class ActionableTranslation extends Component<ActionableTranslationSignature> {
  @service declare intl: IntlService;

  linkify(
    actionTranslation: string,
    href: string,
    options: { linkClass?: string; target?: string; rel?: string }
  ) {
    const { linkClass = 'tio-anchor', target, rel } = options;
    const linkText = this.intl.t(actionTranslation);

    const formattedAttributes = Object.entries({ target, rel })
      .filter(([_attributeKey, attributeValue]) => attributeValue !== undefined)
      .map(([attributeKey, attributeValue]) => `${attributeKey}=${attributeValue}`)
      .join(' ');

    return htmlSafe(
      `<a href="${href}" class="${
        linkClass || 'tio-anchor'
      }" ${formattedAttributes}>${linkText}</a>`
    );
  }

  buttonify(actionTranslation: string, actionName: string, buttonClass?: string) {
    const buttonText = this.intl.t(actionTranslation);
    return htmlSafe(
      // linter made this ugly
      `<button data-action-name="${actionName}" class=${
        buttonClass || 'tio-anchor'
      }>${buttonText}</button>`
    );
  }

  get linkTranslationDataArgs() {
    const { links = {} } = this.args;
    return Object.keys(links).reduce<{ [key: string]: SafeString }>((currentLinks, key) => {
      const link = links[key];
      const { t, href, linkClass, target, rel } = link!;
      return { ...currentLinks, [key]: this.linkify(t, href, { linkClass, target, rel }) };
    }, {});
  }

  get buttonTranslationDataArgs() {
    const { actions = {} } = this.args;
    return Object.keys(actions).reduce<{ [key: string]: SafeString }>((currentButtons, key) => {
      const action = actions[key];
      return { ...currentButtons, [key]: this.buttonify(action!.t, key, action!.buttonClass) };
    }, {});
  }

  get translationDataArgs() {
    const { params = {} } = this.args;
    return {
      ...this.linkTranslationDataArgs,
      ...this.buttonTranslationDataArgs,
      ...params,
      htmlSafe: true,
    };
  }

  get content() {
    return this.intl.t(this.args.t, this.translationDataArgs);
  }

  onMount = modifier((element: HTMLElement) => {
    const clickHandler = (event: Event) => {
      if (event.target instanceof HTMLElement) {
        const button = event.target.closest('button');
        if (button) {
          event.preventDefault();
          event.stopPropagation();
          if (this.args.actions) {
            const action = this.args.actions[<string>button.getAttribute('data-action-name')];
            if (action?.onClick) {
              action.onClick();
            }
          }
        }
      }
    };

    element.addEventListener('click', clickHandler);

    return () => {
      element.removeEventListener('click', clickHandler);
    };
  });

  <template>
    <span {{this.onMount}}>{{this.content}}</span>
  </template>
}

export default ActionableTranslation;
