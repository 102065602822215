import { action } from '@ember/object';
import { Button } from './buttons.gts';
import { htmlSafe } from '@ember/template';
import { on } from '@ember/modifier';
import { tracked } from '@glimmer/tracking';
import ChevronDown from 'ember-static-heroicons/components/outline-24/chevron-down';
import ChevronUp from 'ember-static-heroicons/components/outline-24/chevron-up';
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import type Owner from '@ember/owner';

export type ListItem = {
  heading: string;
  body: string;
  ordinal?: number;
};

export interface StackedListItemSignature {
  Args: {
    // item: ListItem;
    isExpandable: boolean;
  };
  Element: HTMLUListElement;
  Blocks: {
    header: [];
    body: [];
  };
}

export interface StackedListSignature {
  Args: {
    items: ListItem[];
    isExpandable: boolean;
  };
  Element: HTMLUListElement;
}

class StackedListItem extends Component<StackedListItemSignature> {
  @tracked showContent = false;

  constructor(owner: Owner, args: StackedListItemSignature['Args']) {
    super(owner, args);
    this.showContent = args.isExpandable ? false : true;
  }

  @action
  toggleContent() {
    this.showContent = !this.showContent;
  }

  <template>
    <li class="py-4">
      <div>
        <div class="flex items-center justify-between">
          {{yield to="header"}}
          {{#if @isExpandable}}
            <Button
              @type="button"
              {{on "click" this.toggleContent}}
              @appearance="minimal"
              class="flex justify-center items-center w-9 h-9 rounded-full bg-tio-gray-100"
            >
              {{#if this.showContent}}
                <ChevronUp class="h-6 w-6 text-gray-500" />
              {{else}}
                <ChevronDown class="h-6 w-6 text-gray-500" />
              {{/if}}
            </Button>
          {{/if}}
        </div>
        {{#if this.showContent}}
          <div class="mt-1 mr-10 text-sm text-gray-500 text-balance leading-relaxed tracking-wide">
            {{yield to="body"}}
          </div>
        {{/if}}
      </div>
    </li>
  </template>
}

const StackedList: TOC<StackedListSignature> = <template>
  <ul role="list" class="divide-y divide-gray-200" ...attributes>
    {{#each @items as |item|}}
      <StackedListItem @isExpandable={{@isExpandable}} class="not:first:border-t">
        <:header>
          {{htmlSafe item.heading}}
        </:header>
        <:body>
          {{htmlSafe item.body}}
        </:body>
      </StackedListItem>
    {{/each}}
  </ul>
</template>;

export default StackedList;
