import { Button as InitialButton, ToggleButton, Chip, ButtonGroup } from '@frontile/buttons';
import ArrowPath from 'ember-static-heroicons/components/outline-24/arrow-path';
import type { ButtonArgs as InitialButtonArgs } from '@frontile/buttons';
import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import { Popover } from './overlays.gts';

export interface ButtonArgs {
  Args: InitialButtonArgs & {
    isRunning?: boolean;
    // Wether or not to show the info button for a popover description.
    showDescription?: boolean;
    // The description to show in the popover, but also used for screen readers.
    description?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLButtonElement;
}
/* Updates the implementation of how the button is rendered to include a spinner
   when the button is running. Long term we should look at provider context or
   see if a spinner can be added to the main library. The alternative is to make
   a component that just provides the content inside the button. But we also want
   the button to disable when running. */

const Button: TOC<ButtonArgs> = <template>
  {{#if @showDescription}}
    <Popover @placement="top-end" as |p|>
      <ButtonGroup
        @appearance={{@appearance}}
        @intent={{@intent}}
        @size={{@size}}
        @class={{concat @class " relative"}}
        disabled={{if @isRunning true false}}
        aria-label={{@description}}
        as |g|
      >
        <g.Button @class="rounded-r-none border-r-0" ...attributes>
          <div
            class="transition-opacity duration-500 ease-in-out
              {{if @isRunning 'opacity-100' 'opacity-0 pointer-events-none'}}
              flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          >
            <ArrowPath class="w-6 animate-spin" />
          </div>
          <div
            class="transition-opacity duration-500 ease-in-out
              {{if @isRunning 'opacity-0 pointer-events-none' 'opacity-100'}}
              flex justify-center space-x-4"
          >
            <span class="flex" aria-hidden="true">{{yield}}</span>
          </div>
        </g.Button>
        <g.Button
          @class="w-12 rounded-l-none border-l-gray-600"
          {{p.trigger}}
          {{p.anchor}}
        >?</g.Button>
      </ButtonGroup>
      {{! Leave the faded. This will prevent weird interactions when multiple buttons on screen have info.}}
      <p.Content @class="p-2 prose" @backdrop="faded">
        {{@description}}
      </p.Content>
    </Popover>
  {{else}}
    <InitialButton
      @type={{@type}}
      @appearance={{@appearance}}
      @intent={{@intent}}
      @size={{@size}}
      @isRenderless={{@isRenderless}}
      @class={{concat @class " relative"}}
      @isInGroup={{@isInGroup}}
      disabled={{if @isRunning true false}}
      aria-label={{@description}}
      ...attributes
    >
      <div
        class="transition-opacity duration-500 ease-in-out
          {{if @isRunning 'opacity-100' 'opacity-0 pointer-events-none'}}
          flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <ArrowPath class="w-6 animate-spin" />
      </div>
      <div
        class="transition-opacity duration-500 ease-in-out
          {{if @isRunning 'opacity-0 pointer-events-none' 'opacity-100'}}
          flex justify-center space-x-4"
      >
        <span class="flex" aria-hidden="true">{{yield}}</span>
      </div>
    </InitialButton>
  {{/if}}
</template>;

export { Button, Chip, ToggleButton, ButtonGroup };
