import Model, { attr, belongsTo } from '@ember-data/model';
import type EmployeeModel from './employee';

interface Processed {
  fields: Record<string, unknown>;
  hashKeys: Record<string, unknown>;
  merged: Record<string, unknown>;
}

export default class EmployeeAdditionModel extends Model {
  @attr declare raw: Record<string, unknown>;
  @attr declare operational: Record<string, unknown>;
  @attr declare overrides: Record<string, unknown>[];
  @attr declare processed: Processed;

  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('employee', { async: false, inverse: 'employeeAddition' })
  declare employee: EmployeeModel;

  /**************************
   **  Computed Properties **
   **************************/
  get editableMergedFields() {
    const mergedData = this.processed.merged || {};
    const result: Record<string, unknown> = {};
    // Remove the fields that are not needed
    for (const [key, value] of Object.entries(mergedData)) {
      if (
        ![
          'payrollId',
          'id',
          'isActive',
          'createdAt',
          'updatedAt',
          'role',
          'contributionAmount',
          'contributionPlanKey',
        ].includes(key)
      ) {
        result[key] = value;
      }
    }

    return result;
  }

  get allAttributes() {
    return {
      raw: this.raw,
      fields: this.processed.fields,
      merged: this.processed.merged,
      hashKeys: this.processed.hashKeys,
      overrides: this.overrides,
    };
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-addition': EmployeeAdditionModel;
  }
}
