import { eq } from 'tio-ui/utilities';
import { hash } from '@ember/helper';
import type { TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';

const ActionableRow: TOC<{
  Args: { striped: boolean };
  Element: HTMLDivElement;
  Blocks: { default: []; action?: [] };
}> = <template>
  <div
    class="py-3 flex flex-col md:flex-row flex-nowrap gap-4 {{if @striped 'even:bg-gray-50'}}"
    role="list"
    ...attributes
  >
    {{yield}}
  </div>
</template>;

const ActionableTerm: TOC<{ Element: HTMLElement; Blocks: { default: [] } }> = <template>
  <div class="shrink text-sm font-medium text-gray-900" role="term" ...attributes>
    {{yield}}
  </div>
</template>;

const ActionableDetails: TOC<{ Element: HTMLDivElement; Blocks: { default: [] } }> = <template>
  <div
    class="grow grid items-center sm:grid-cols-1 lg:grid-flow-col lg:grid-cols-[repeat(auto-fit,minmax(150px,1fr))]"
    ...attributes
  >
    {{yield}}
  </div>
</template>;

const ActionableItem: TOC<{ Element: HTMLElement; Blocks: { default: [] } }> = <template>
  <div class="col-span-1 text-sm text-gray-700 sm:mt-0" role="listitem" ...attributes>
    {{yield}}
  </div>
</template>;

const ActionButton: TOC<{ Element: HTMLDivElement; Blocks: { default: [] } }> = <template>
  <div class="shrink-0 text-right self-center flex gap-4" role="button" ...attributes>
    {{yield}}
  </div>
</template>;

interface ActionableListSignature {
  Args: {
    striped?: boolean;
  };
  Blocks: {
    default: [
      {
        Row: WithBoundArgs<typeof ActionableRow, 'striped'>;
        Term: typeof ActionableTerm;
        Item: typeof ActionableItem;
        Action?: typeof ActionButton;
        Details: typeof ActionableDetails;
      },
    ];
  };
  Element: HTMLElement;
}

const ActionableList: TOC<ActionableListSignature> = <template>
  <div class="divide-y divide-gray-100" ...attributes>
    {{yield
      (hash
        Row=(component ActionableRow striped=(if (eq @striped undefined) true @striped))
        Action=ActionButton
        Details=ActionableDetails
        Item=ActionableItem
        Term=ActionableTerm
      )
    }}
  </div>
</template>;

export default ActionableList;

// HACK so tailwind doesn't purge classes
// even:bg-gray-50
