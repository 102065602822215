import type { TOC } from '@ember/component/template-only';
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';

// TODO: this could potentially benefit from tailwind variants for finer
// grained size/spacing (small or xs variants for more data-dense views,
// for example) or control over preDescined striping and divider behaviors
// https://www.tailwind-variants.org/docs/variants

interface DescriptionGroupSignature {
  Args: {
    striped?: boolean;
    compact?: boolean;
    divided?: boolean;
  };
  Blocks: { default: [] };
  Element: HTMLElement;
}

const descriptionGroupClasses = (options?: { striped?: boolean; compact?: boolean }) => {
  const { striped, compact } = options || {};
  return [
    compact ? 'p-1' : 'px-4 py-3 sm:px-3',
    'sm:grid',
    'sm:grid-cols-3',
    'sm:gap-4',
    striped && 'even:bg-gray-50',
  ]
    .filter(Boolean)
    .join(' ');
};

const DescriptionGroup: TOC<DescriptionGroupSignature> = <template>
  <div ...attributes class={{descriptionGroupClasses (hash striped=@striped compact=@compact)}}>
    {{yield}}
  </div>
</template>;

const DescriptionTerm: TOC<{ Element: HTMLElement; Blocks: { default: [] } }> = <template>
  <dt ...attributes class="text-sm font-medium leading-6 text-gray-900">
    {{yield}}
  </dt>
</template>;

const DescriptionDetails: TOC<{ Element: HTMLElement; Blocks: { default: [] } }> = <template>
  <dd ...attributes class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
    {{yield}}
  </dd>
</template>;

interface DescriptionListSignature {
  Args: {
    striped?: boolean;
    compact?: boolean;
    divided?: boolean;
  };
  Blocks: {
    default: [
      {
        Group: WithBoundArgs<typeof DescriptionGroup, 'striped' | 'compact'>;
        Term: ComponentLike<{ Element: HTMLElement; Blocks: { default: [] } }>;
        Details: ComponentLike<{ Element: HTMLElement; Blocks: { default: [] } }>;
      },
    ];
  };
  Element: HTMLDListElement;
}

const descriptionListClasses = (options?: { divided?: boolean }) => {
  const { divided } = options || {};
  return [divided && 'divide-y divide-gray-100'].filter(Boolean).join(' ');
};

const DescriptionList: TOC<DescriptionListSignature> = <template>
  <dl ...attributes class={{descriptionListClasses (hash divided=@divided)}}>
    {{yield
      (hash
        Group=(component DescriptionGroup striped=@striped compact=@compact)
        Term=DescriptionTerm
        Details=DescriptionDetails
      )
    }}
  </dl>
</template>;

export default DescriptionList;
