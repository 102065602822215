import { Button } from './buttons.gts';
import { on } from '@ember/modifier';
import { or } from '../utilities.ts';
import ArrowPath from 'ember-static-heroicons/components/outline-24/arrow-path';
import type { TOC } from '@ember/component/template-only';

// Re-export frontile modal to consolidate where logic is imported.
export { Modal } from '@frontile/overlays';

/**
 * Base interface for the signature of the `ModalFooter` component.
 * Defines the basic structure required for the footer, including
 * the state of the modal (whether a task is running) and the submit action.
 */
interface ModalFooterSignatureBase {
  /**
   * Indicates if a task is currently running.
   * Used to control the visibility of a spinner and button states.
   */
  isRunning?: boolean;

  /**
   * Function to be called when the submit button is clicked.
   */
  onSubmit: () => void;

  /**
   * Optional text to display on the submit button. Defaults to "Submit".
   */
  submitText?: string;

  /**
   * Optional flag to disable the submit button.
   */
  submitDisabled?: boolean;
}

/**
 * Interface for the `ModalFooter` component when a cancel action is provided.
 * Extends `ModalFooterSignatureBase` and adds an optional cancel action and text.
 */
interface ModalFooterWithCancel {
  Args: ModalFooterSignatureBase & {
    /**
     * Function to be called when the cancel button is clicked.
     */
    onCancel: () => void;

    /**
     * Optional text to display on the cancel button. Defaults to "Cancel".
     */
    cancelText?: string;
  };
  Blocks: {
    /**
     * A block named `left` that allows content to be yielded to the left side of the footer.
     */
    left: [];
  };
}

/**
 * Interface for the `ModalFooter` component when no cancel action is provided.
 * Extends `ModalFooterSignatureBase` but omits the cancel action and text.
 */
interface ModalFooterWithoutCancel extends ModalFooterSignatureBase {
  Args: ModalFooterSignatureBase & {
    /**
     * Optional cancel action is not allowed in this configuration.
     */
    onCancel?: undefined;

    /**
     * The cancel text is not allowed in this configuration.
     */
    cancelText?: never;
  };
  Blocks: {
    /**
     * A block named `left` that allows content to be yielded to the left side of the footer.
     */
    left: [];
  };
}

/**
 * Type definition for the `ModalFooterSignature`, which can either include
 * a cancel action (`ModalFooterWithCancel`) or not (`ModalFooterWithoutCancel`).
 */
type ModalFooterSignature = ModalFooterWithCancel | ModalFooterWithoutCancel;

/**
 * The `Footer` component is a template-only component that renders the footer
 * for a modal dialog. It conditionally shows a cancel button if an `onCancel`
 * action is provided. The component also displays a spinner when a task is
 * running, using transitions to fade in and out the spinner and buttons.
 *
 * @template ModalFooterSignature - The component signature, which dictates the available arguments and blocks.
 */
export const Footer: TOC<ModalFooterSignature> = <template>
  {{! Spinner Div: Show and fade in when submitTask is running }}
  <div
    class="transition-opacity duration-500 ease-in-out
      {{if @isRunning 'opacity-100' 'opacity-0 pointer-events-none'}}
      flex items-center justify-center absolute inset-0"
  >
    <ArrowPath class="w-6 animate-spin" />
  </div>

  {{! Button Div: Fade out when submitTask is running }}
  <div
    class="transition-opacity duration-500 ease-in-out
      {{if @isRunning 'opacity-0 pointer-events-none' 'opacity-100'}}
      flex justify-between w-full space-x-4"
  >
    <div>
      {{yield to="left"}}
    </div>
    <div>
      {{#if @onCancel}}
        <Button
          {{on "click" @onCancel}}
          @appearance="outlined"
          @intent="danger"
          data-test-modal-cancel
        >
          {{or @cancelText "Cancel"}}
        </Button>
      {{/if}}
      <Button
        {{on "click" @onSubmit}}
        @intent="primary"
        disabled={{@submitDisabled}}
        data-test-modal-submit
      >
        {{or @submitText "Submit"}}
      </Button>
    </div>
  </div>
</template>;
